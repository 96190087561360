/* Selected Style */
.selected-style {
  /* Choose a suitable class name */
  width: 90%;
  padding-top: 16px; /* Convert 'py: 2' to pixel value */
  padding-bottom: 16px;
  padding-right: 0;
  margin-top: 8px; /* Convert 'my: 1' to pixel value */
  margin-bottom: 8px;
  cursor: pointer;
  background-image: linear-gradient(
    90deg,
    rgba(251, 255, 232, 0.5) 0%,
    rgba(207, 251, 245, 0.5) 50%,
    rgba(238, 243, 255, 0.5) 100%
  );
  border-radius: 8px;
}

/* Unselected Style */
.unselected-style {
  width: 90%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 8px;
}

/* Unselected Style Hover Effect */
.unselected-style:hover {
  background-image: linear-gradient(
    90deg,
    rgba(251, 255, 232, 0.5) 0%,
    rgba(207, 251, 245, 0.5) 50%,
    rgba(238, 243, 255, 0.5) 100%
  );
  border-radius: 4px;
}
