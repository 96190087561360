body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}

.dropdown-open {
  border-bottom: 1px solid #ddd !important; /* Adjust style as needed */
}
